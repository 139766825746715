import { default as poi1QJWkRULQzMeta } from "/builds/nht4944308/brands/ixpfx/ixpfx-verification-center/portal/pages/evidence/poi.vue?macro=true";
import { default as porI0sCw1yhHGMeta } from "/builds/nht4944308/brands/ixpfx/ixpfx-verification-center/portal/pages/evidence/por.vue?macro=true";
import { default as indext2PfaQubF6Meta } from "/builds/nht4944308/brands/ixpfx/ixpfx-verification-center/portal/pages/index.vue?macro=true";
import { default as national_45idY8k9DiV0EuMeta } from "/builds/nht4944308/brands/ixpfx/ixpfx-verification-center/portal/pages/national-id.vue?macro=true";
import { default as questionnaireXhDZOrQBxJMeta } from "/builds/nht4944308/brands/ixpfx/ixpfx-verification-center/portal/pages/questionnaire.vue?macro=true";
import { default as component_45stubKMTjRjSjUPMeta } from "/builds/nht4944308/brands/ixpfx/ixpfx-verification-center/portal/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubKMTjRjSjUP } from "/builds/nht4944308/brands/ixpfx/ixpfx-verification-center/portal/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: poi1QJWkRULQzMeta?.name ?? "evidence-poi___en",
    path: poi1QJWkRULQzMeta?.path ?? "/evidence/poi",
    meta: poi1QJWkRULQzMeta || {},
    alias: poi1QJWkRULQzMeta?.alias || [],
    redirect: poi1QJWkRULQzMeta?.redirect,
    component: () => import("/builds/nht4944308/brands/ixpfx/ixpfx-verification-center/portal/pages/evidence/poi.vue").then(m => m.default || m)
  },
  {
    name: porI0sCw1yhHGMeta?.name ?? "evidence-por___en",
    path: porI0sCw1yhHGMeta?.path ?? "/evidence/por",
    meta: porI0sCw1yhHGMeta || {},
    alias: porI0sCw1yhHGMeta?.alias || [],
    redirect: porI0sCw1yhHGMeta?.redirect,
    component: () => import("/builds/nht4944308/brands/ixpfx/ixpfx-verification-center/portal/pages/evidence/por.vue").then(m => m.default || m)
  },
  {
    name: indext2PfaQubF6Meta?.name ?? "index___en",
    path: indext2PfaQubF6Meta?.path ?? "/",
    meta: indext2PfaQubF6Meta || {},
    alias: indext2PfaQubF6Meta?.alias || [],
    redirect: indext2PfaQubF6Meta?.redirect,
    component: () => import("/builds/nht4944308/brands/ixpfx/ixpfx-verification-center/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: national_45idY8k9DiV0EuMeta?.name ?? "national-id___en",
    path: national_45idY8k9DiV0EuMeta?.path ?? "/national-id",
    meta: national_45idY8k9DiV0EuMeta || {},
    alias: national_45idY8k9DiV0EuMeta?.alias || [],
    redirect: national_45idY8k9DiV0EuMeta?.redirect,
    component: () => import("/builds/nht4944308/brands/ixpfx/ixpfx-verification-center/portal/pages/national-id.vue").then(m => m.default || m)
  },
  {
    name: questionnaireXhDZOrQBxJMeta?.name ?? "questionnaire___en",
    path: questionnaireXhDZOrQBxJMeta?.path ?? "/questionnaire",
    meta: questionnaireXhDZOrQBxJMeta || {},
    alias: questionnaireXhDZOrQBxJMeta?.alias || [],
    redirect: questionnaireXhDZOrQBxJMeta?.redirect,
    component: () => import("/builds/nht4944308/brands/ixpfx/ixpfx-verification-center/portal/pages/questionnaire.vue").then(m => m.default || m)
  },
  {
    name: component_45stubKMTjRjSjUPMeta?.name ?? undefined,
    path: component_45stubKMTjRjSjUPMeta?.path ?? "/sitemap.xml",
    meta: component_45stubKMTjRjSjUPMeta || {},
    alias: component_45stubKMTjRjSjUPMeta?.alias || [],
    redirect: component_45stubKMTjRjSjUPMeta?.redirect,
    component: component_45stubKMTjRjSjUP
  }
]