import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from 'vuetify'
import type { VuetifyOptions } from 'vuetify'

export const colors = {
  'background': '#F3F3F3',
  'surface': '#FFFFFF',
  'primary': '#000D81',
  'secondary': '#F6931E',
  'accent': '#0800C4',
  'highlight': '#F6931E',
  'notify': '#E43333',
  'success': '#06972E',
  'info': '#3F51B5',
  'warning': '#FFC805',
  'error': '#E43333',
  'question': '#554C7D',
  'cancel': '#AFACAC',
  'upload-new': '#0800C4',
  'upload-paused': '#FFC107',
  'upload-uploading': '#74CF8D',
  'upload-complete': '#74CF8D',
  'upload-error': '#E43333',
  'status-missing': '#434252',
  'status-new': '#428EDA',
  'status-reviewing': '#E49A10',
  'status-approved': '#06972E',
  'status-incomplete': '#E4622A',
  'status-incomplete-can-trade': '#17A68D',
  'status-rejected': '#E43333',
  'status-none': '#434252',
  'status-unknown': '#312E53',
}
const allColorNames = new Set(Object.keys(colors))
export const config: VuetifyOptions = {
  ssr: true,
  theme: {
    defaultTheme: 'ixpfx',
    variations: {
      colors: Array.from(allColorNames),
      lighten: 5,
      darken: 5,
    },
    themes: {
      ixpfx: {
        dark: false,
        colors,
      },
    },
  },
  aliases: {},
  defaults: {
    Swal: {
      backdrop: true,
      color: '#000000',
      background: colors.surface,
      customClass: {
        title: 'text-h6',
        htmlContainer: 'text-body-2',
        popup:
          'v-card v-theme--ixpfx v-card--density-default v-card--variant-elevated bg-surface elevation-3 pb-3',
        confirmButton:
          'v-btn v-btn--elevated v-theme--ixpfx bg-primary v-btn--density-default v-btn--size-default v-btn--variant-elevated',
        denyButton:
          'v-btn v-btn--elevated v-theme--ixpfx bg-error v-btn--density-default v-btn--size-default v-btn--variant-elevated',
        cancelButton:
          'v-btn v-btn--elevated v-theme--ixpfx bg-cancel v-btn--density-default v-btn--size-default v-btn--variant-elevated',
      },
      confirmButtonColor: colors.primary,
      denyButtonColor: colors.error,
      cancelButtonColor: colors.cancel,
      buttonsStyling: true,
      reverseButtons: true,
    },
    VTextField: {
      variant: 'outlined',
      bgColor: 'surface',
      baseColor: 'surface-darken-4',
      hideDetails: 'auto',
    },
    VSelect: {
      variant: 'outlined',
      bgColor: 'surface',
      baseColor: 'surface-darken-4',
      hideDetails: 'auto',
      itemTitle: 'title',
      itemValue: 'value',
    },
    VAutocomplete: {
      variant: 'outlined',
      bgColor: 'surface',
      baseColor: 'surface-darken-4',
      hideDetails: 'auto',
      itemTitle: 'title',
      itemValue: 'value',
    },
    VSwitch: {
      color: 'primary',
      hideDetails: 'auto',
    },
    VFileInput: {
      variant: 'outlined',
      bgColor: 'surface',
      baseColor: 'surface-darken-4',
      hideDetails: 'auto',
    },
  },
}

export const vuetify = createVuetify(config)
